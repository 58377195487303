import React, { useState } from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { FollowBtn } from "components/follow-btn"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { BrandLogo } from "components/brand-logo"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLine,
  faTwitterSquare,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons"
import {
  faTrophy,
  faTicketAlt,
  faQuestionCircle,
  faBook,
  faChartBar,
  faMapMarkedAlt,
  faEnvelopeOpenText,
  faWindowMaximize,
  faPaperPlane,
  faGifts,
  faGift,
  faImages,
  faDatabase,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons"

const Index = () => {
  const [isShowFollowBtn, setIsShowFollowBtn] = useState(false)
  useScrollPosition(({ prevPos, currPos }) => {
    const isVisible = currPos.y > -250 ? false : true
    setIsShowFollowBtn(isVisible)
  })
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo pagetitle="パークロット" />
        <main className="main">
          <section className="hero">
            <div className="hero__container">
              <div className="hero__inner">
                <div className="hero__catch">
                  {/* FIXME: コピーは仮 */}
                  <h1 className="hero__title">
                    フォロワー獲得と
                    <br />
                    販促を加速させる
                    <br />
                    SNSキャンペーンツール
                  </h1>
                  <p className="hero__lead">
                    インスタントウィン・クーポン配布・UGC活用！
                    <br />
                    様々なSNSキャンペーンができる！
                  </p>
                  <div className="hero__icons">
                    <FontAwesomeIcon
                      icon={faTwitterSquare}
                      className="icon--twitter"
                    />
                    <FontAwesomeIcon
                      icon={faInstagramSquare}
                      className="icon--ig"
                    />
                    <FontAwesomeIcon icon={faLine} className="icon--line" />
                  </div>
                  <div className="hero__features">
                    <div>
                      <img
                        alt="豊富な機能"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/words_01.png"
                        loading="lazy"
                        width="113px"
                      />
                    </div>
                    <div>
                      <img
                        alt="5万円から"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/words_02.png"
                        loading="lazy"
                        width="113px"
                      />
                    </div>
                    <div>
                      <img
                        alt="最短1日で開催"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/words_03.png"
                        loading="lazy"
                        width="113px"
                      />
                    </div>
                    <div>
                      <img
                        layout="constrained"
                        alt="コスパ抜群"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/words_04.png"
                        loading="lazy"
                        width="113px"
                      />
                    </div>
                  </div>
                </div>
                <figure className="hero__images">
                  {/* FIXME: altは正式決定したコピーに変更 */}
                  <img
                    layout="constrained"
                    alt="SNSキャンペーン施策の企画から実行までご支援！"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/fv_mock.png"
                    loading="lazy"
                    width="598px"
                  />
                </figure>
              </div>
              <div className="hero__btn-container md:flex gap-4 mt-12">
                <a
                  className="ga-download-top text-2xl mb-8 text-white font-bold bg-accent w-96 h-20 shadow-xl py-6 pl-10 block hover:shadow-none hover:text-white"
                  href="https://promoter.park-lot.com/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料で使ってみる　　＞
                </a>
                <a
                  className="ga-download-top text-2xl mb-8 text-title font-bold bg-white w-96 h-20 shadow-xl py-6 pl-10 block border-solid border-2 border-accent hover:shadow-none hover:text-title"
                  href="https://hub.park-lot.com/document-download"
                  target="_blank"
                  rel="noreferrer"
                >
                  資料ダウンロード　　＞
                </a>
              </div>
            </div>
          </section>
          <BrandLogo />
          <section className="trouble index">
            <div className="trouble__container l-container">
              <h2 className="index__title">
                こんな悩みを
                <br />
                お持ちではありませんか？
              </h2>
              <ul className="trouble__list">
                <li className="trouble__item">
                  予算と実施回数の
                  <br />
                  制限があり、
                  <br />
                  企画に時間が
                  <br />
                  かかってしまう
                  <figure className="trouble__img-wrapper">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="企画に時間がかかってしまう"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/person-1.png"
                      loading="lazy"
                      width="70px"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  キャンペーン運用の手間を減らしたい！
                  <figure className="trouble__img-wrapper">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="キャンペーン運用の手間を減らしたい！"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/person-2.png"
                      loading="lazy"
                      width="70px"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  時間をかけず
                  <br />
                  効果的に商品を
                  <br />
                  PRしたい！
                  <figure className="trouble__img-wrapper">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="時間をかけず効果的に商品をPRしたい！"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/person-3.png"
                      loading="lazy"
                      width="70px"
                    />
                  </figure>
                </li>
                <li className="trouble__item">
                  キャンペーンを
                  <br />
                  開催しても
                  <br />
                  売上に繋がらない
                  <figure className="trouble__img-wrapper">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="キャンペーンを開催しても売上に繋がらない"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/person-4.png"
                      loading="lazy"
                      width="70px"
                    />
                  </figure>
                </li>
              </ul>
            </div>
          </section>
          <section className="solution">
            <div className="solution__container l-container">
              <h2 className="solution__title">
                <span className="solution__small">そのお悩み</span>
                <span className="solution__row">
                  <img
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="パークロット"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/solution-logo.png"
                    className="solution__logo"
                    loading="lazy"
                    width="300px"
                  />
                  が
                </span>
                解決します！！
              </h2>
              <div className="solution__card-container">
                <div className="solution__card">
                  <h3 className="solution__subtitle">面倒な作業は自動化！</h3>
                  <div className="solution__img">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="面倒な作業は自動化！"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/solution-1.png"
                      loading="lazy"
                    />
                  </div>
                  <p className="solution__text">
                    PARKLoTなら「
                    <strong className="index__strong">抽選作業</strong>」「
                    <strong className="index__strong">応募情報の収集</strong>
                    」「
                    <strong className="index__strong">当落結果の連絡</strong>
                    」全てを自動化！
                    <br />
                    事務的な作業の時間を短縮し、顧客とのコミュニケーションなど、本質的な価値を生み出すための時間を創出します。
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">思い立ったら即実施！</h3>
                  <div className="solution__img">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="思い立ったら即実施！"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/solution-2.png"
                      loading="lazy"
                    />
                  </div>
                  <p className="solution__text">
                    <strong className="index__strong">最短10分で</strong>
                    キャンペーン開始！
                    <br />
                    チャンスを逃さずタイムリーにサービスや商品のPRを行うことができるので、
                    <strong className="index__strong">
                      すぐに成果に繋がります。
                    </strong>
                  </p>
                </div>
                <div className="solution__card">
                  <h3 className="solution__subtitle">改善・追加も何回でも！</h3>
                  <div className="solution__img">
                    <img
                      layout="constrained"
                      placeholder="tracedSVG"
                      alt="改善・追加も何回でも！"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/solution-3.png"
                      loading="lazy"
                    />
                  </div>
                  <p className="solution__text">
                    キャンペーンは短期施策と中長期施策を組み合わせることが重要！
                    <br />
                    PARKLoTのキャンペーンは
                    <strong className="index__strong">
                      月額制で何回でも実施できる
                    </strong>
                    から、効果的なキャンペーンを実施でき、
                    <strong className="index__strong">LTVを最大化</strong>
                    します。
                  </p>
                </div>
              </div>
              <div className="solution__flow flow">
                <div className="flow__text-container">
                  <div className="flow__text-box">
                    <h3 className="flow__title">
                      定額プランなら
                      <strong className="index__strong">
                        完全無制限<small className="index__small">※</small>
                      </strong>
                      で<br />
                      キャンペーンが実施できます
                    </h3>
                    <p className="index__text">
                      一般的にTwitterでキャンペーンを実施しようとすると、予算や実施回数が限られている分企画に時間をかけすぎてしまい、反応がイマイチでも時間や予算のコストが大きく、再実施に躊躇してしまいがちです。
                    </p>
                    <p className="index__text">
                      PARKLoTなら、キャンペーンの反応を見ながら試行錯誤、あるいは思い立ったらすぐにキャンペーンが実施できるので、
                      <br />
                      <strong className="index__strong">
                        チャンスを逃さず、サービスや商品のPRを成果に繋げていけます。
                      </strong>
                    </p>
                    <p className="index__text flow__detail">
                      また改善の際、予算確保や契約に関する時間をカットできるので、
                      <strong className="index__strong">
                        成果に向けたサイクルが鈍化せず、キャンペーンに注力できます。
                      </strong>
                    </p>
                    <p className="flow__notes index__notes">
                      ※プラン・利用状況によって制限がかかる場合があります
                    </p>
                  </div>
                  <div className="flow__card">
                    <p className="flow__text">
                      社内の検討・契約に関する時間をカット
                    </p>
                    <p className="flow__text">
                      成果に向けたPDCAサイクルが
                      <br />
                      高速に回せる！
                    </p>
                    <div className="flow__img--sp">
                      <img
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="成果に向けたPDCAサイクルが"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/flow-sp.png"
                        loading="lazy"
                        width="324ox"
                      />
                    </div>
                    <div className="flow__img--pc">
                      <img
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="高速に回せる！"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/flow-pc.png"
                        loading="lazy"
                        width="400ox"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="feature index">
            <div className="feature__container l-container">
              <h2 className="index__title">
                PARKLoTで
                <br />
                できるキャンペーン
              </h2>
              <p className="index__lead">
                バラエティに富んだキャンペーンで、
                <br />
                商品・サービスの魅力を拡散。
              </p>
              <p className="index__lead">
                ワクワクするキャンペーンを
                <br />
                顧客と一緒に楽しむことで、
                <br />
                ファンを増やします。
              </p>
              <ul className="feature__list">
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTrophy}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">インスタントウィン</p>
                  <p className="feature__item--text">
                    結果はDMで通知。当落結果はTwitter認証不要で確認できる！「その場で当たる」大人気施策。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faTicketAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">クーポン配布</p>
                  <p className="feature__item--text">
                    実店舗への集客を支援！「消込型」「時限式」「期間中無制限」3種類のクーポンを配布。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">
                    クイズ・診断・ミニゲーム
                  </p>
                  <p className="feature__item--text">
                    自社サイトへの誘導に効果バツグン！スクラッチやスロット等さらにワクワク感を演出できるキャンペーン。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">ギフト券自動送付</p>
                  <p className="feature__item--text">
                    当選者へDMでデジタルギフト券を送付！人気のTwitterフォローRTキャンペーンを即開催！
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faMobileAlt}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">Twitter運用</p>
                  <p className="feature__item--text">
                    予約投稿・RTキャンペーン自動化・DM送付代行まで！成果の出るTwitter集客へ。
                  </p>
                </li>
                <li className="feature__item">
                  <div className="feature__icon-wrapper">
                    <FontAwesomeIcon
                      icon={faChartBar}
                      className="feature__item-icon"
                    />
                  </div>
                  <p className="feature__item--title">リツートユーザー収集</p>
                  <p className="feature__item--text">
                    リツイートユーザーを全件取得。さまざまな分析に活用し効果的なキャンペーン企画を支援！
                  </p>
                </li>
              </ul>
              <div className="option">
                <h3 className="option__title">さらに豊富な無料オプション</h3>
                <ul className="option__list">
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faMapMarkedAlt}
                        className="option__icon"
                      />
                    </div>
                    発送先住所収集
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faEnvelopeOpenText}
                        className="option__icon"
                      />
                    </div>
                    当選者自動送信
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faWindowMaximize}
                        className="option__icon"
                      />
                    </div>
                    WEBサイト誘導
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faLine} className="option__icon" />
                    </div>
                    LINE誘導
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faBook} className="option__icon" />
                    </div>
                    資料配布
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faGifts}
                        className="option__icon"
                      />
                    </div>
                    プレゼントごとの当選確率設定
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon icon={faGift} className="option__icon" />
                    </div>
                    当選基準設定
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faImages}
                        className="option__icon"
                      />
                    </div>
                    当選落選画像カスタム
                  </li>
                  <li className="option__item">
                    <div className="option__icon-wrapper">
                      <FontAwesomeIcon
                        icon={faDatabase}
                        className="option__icon"
                      />
                    </div>
                    データ収集・CSV出力
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              3分でわかるPARKLoT・
              <br />
              成功事例集を
              <br />
              いますぐダウンロード！
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              資料をダウンロード（無料）
            </a>
          </div>
          <section className="case">
            <div className="case__container l-container">
              <h2 className="index__title">開催事例</h2>
              <p className="index__lead">
                PARKLoTは今までの
                <br />
                Twitterインスタントウィンでは
                <br />
                考えられない、CPFを実現します。
              </p>
              <div className="case__item-container">
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <img
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="考えられないレベルのCPFを実現します"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/case-sutenai.png"
                        maxwidth="155px"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      SUTENAI<span className="case__small">様</span>
                    </h3>
                    <p className="case__text">
                      2020年1月にブランドを立ち上げたばかりのタイミングで、Twitterインスタントウィンキャンペーンを実施。立ち上がったばかりのブランドで予算が限られている中、プロモーションの一環として開始。わずか2週間で、40名のフォロワーが、2960名以上に。
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">フォロワー増加数</p>
                        <p className="case__data">
                          2,934
                          <span className="case__unit">名</span>
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          2.22
                          <span className="case__unit">円※</span>
                        </p>
                        <small className="case__notes">※プレゼント代含む</small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="case__item">
                  <div className="case__head">
                    <div className="case__img-wapper">
                      <img
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt="事例の画像"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/index/case-nor.png"
                        width="142px"
                      />
                    </div>
                  </div>
                  <div className="case__body">
                    <h3 className="case__title">
                      NOR.<span className="case__small">様</span>
                    </h3>
                    <p className="case__text">
                      1年目の新しいブランドコスメブランドを運営されていたNOR.様は、過去に何度か自分たちで運営するキャンペーンを実施し、6,920フォロワーを獲得していましたが、抽選は当選者様とのやりとりなどの運営コストに悩んでいました。そこで、PARKLoTを利用したキャンペーンを実施したところ、過去最高のフォロワー数を獲得し、運営コストを大幅に削減することに成功しました。
                    </p>
                    <div className="case__data-container">
                      <div className="case__data-box">
                        <p className="case__dtitle">フォロワー増加数</p>
                        <p className="case__data">
                          4,270
                          <span className="case__unit">名</span>
                        </p>
                      </div>
                      <div className="case__data-box">
                        <p className="case__dtitle">CPF</p>
                        <p className="case__data">
                          0.7
                          <span className="case__unit">円※</span>
                        </p>
                        <small className="case__notes">※プレゼント代含む</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="price index">
            <div className="price__container l-container">
              <h2 className="index__title">料金</h2>
              <p className="index__lead price__lead">
                さまざまなマーケティング施策に合わせた
                <br />
                おすすめのインスタントウィンキャンペーンプランをご用意しました。
              </p>
              <div className="price-card__container">
                <div className="price-card">
                  <h3 className="price-card__title--hot">
                    マーケティング効果をより強化したい方
                  </h3>
                  <div className="price-card__head--hot">
                    <p className="price-card__plan">定額プラン</p>
                    <p className="price-card__hot-price">
                      <strong className="price-card__hot-price--strong">
                        6
                      </strong>
                      万円〜
                      <span className="price-card__unit">/月</span>
                    </p>
                    <p className="price-card__taxin">(税込66,000円〜)</p>
                    <ul className="price-card__list">
                      <li className="price-card__item">
                        6ヶ月契約：60,000円 /月 (税込66,000円)
                      </li>
                      <li className="price-card__item">
                        年間契約 ：50,000円 /月 (税込55,000円)
                      </li>
                    </ul>
                    <p className="price-card__notes">
                      ※ 6ヶ月以上の契約が必要です。
                    </p>
                  </div>
                  <div className="price-card__body">
                    <p className="price-card__initial">初期費用</p>
                    <p className="price-card__cost">
                      <strong className="price-card__price--strong">10</strong>
                      万円
                    </p>
                    <p className="price-card__taxin">(税込110,000円)</p>
                  </div>
                </div>
              </div>
              <p className="index__lead">
                その他のキャンペーンの料金は
                <br />
                こちらからお問い合わせください。
              </p>
              <a
                className="ga-contact-price price__link"
                href="https://hub.park-lot.com/document-download"
                target="_blank"
                rel="noreferrer"
              >
                料金について問い合わせる
              </a>
            </div>
          </section>
          <section className="qa index">
            <div className="qa__container l-container">
              <h3 className="index__title">よくいただくご質問</h3>
              <div className="qa__box">
                <dl className="qa__list">
                  <dt className="qa__title">
                    本当にこの価格で何回もキャンペーンができるのですか？追加費用はかからないのでしょうか。
                  </dt>
                  <dd className="qa__data">
                    定額プランでご契約いただいた場合、追加費用不要で何回でもキャンペーンを行っていただけます。
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">
                    キャンペーンは最短でいつから始められますか？
                  </dt>
                  <dd className="qa__data">
                    Twitterアカウント・キャンペーン名・プレゼント・キャンペーン期間・ツイート投稿用の画像が揃っていれば、最短で10分程度でキャンペーンを開始できます。
                  </dd>
                </dl>
                <dl className="qa__list">
                  <dt className="qa__title">
                    当選ユーザーへのプレゼントの配送代行は可能ですか？
                  </dt>
                  <dd className="qa__data">
                    当選ユーザー様へ配送代行サービスは行っておりません。当選者様の配送情報はCSVにて出力できます。
                  </dd>
                </dl>
              </div>
            </div>
          </section>
          <div className="contact">
            <p className="contact__text">
              3分でわかるPARKLoT・
              <br />
              成功事例集を
              <br />
              いますぐダウンロード！
            </p>
            <a
              className="ga-contact-middle contact__link btn-white"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              資料をダウンロード（無料）
            </a>
          </div>
        </main>
      </Layout>
      {isShowFollowBtn && <FollowBtn />}
    </>
  )
}

export default Index
